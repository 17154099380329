import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {Button, Col, Row, Tooltip, Statistic, Spin, DatePicker} from 'antd';
import {useOutlet} from 'reconnect.js';

export default function Statistics({}) {
  const [statistic, setStatistic] = useState();
  const [filters, setFilters] = useState({
    start: moment().subtract(1, 'months').format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
  });
  const [loading, setLoading] = useOutlet('loading');
  const [actions] = useOutlet('actions');

  function filtersChange(obj) {
    setFilters((prev) => ({...prev, ...obj}));
  }

  const getRecords = useCallback(async () => {
    setLoading(true);
    try {
      let resp = await actions.getStatistics(filters);
      setStatistic(resp);
    } catch (err) {}
    setLoading(false);
  }, [filters]);

  useEffect(() => {
    getRecords();
  }, [getRecords]);

  if (!statistic) {
    return <Spin style={{padding: 20, margin: '0 auto', display: 'block'}} />;
  } else {
    return (
      <Wrapper>
        <Row align="middle" gutter={[16, 30]} style={{marginBottom: 30}}>
          <Col span={22}>
            <Title>儀表板</Title>
          </Col>
          <Col span={2}>
            <Button loading={loading} onClick={() => getRecords()}>
              刷新
            </Button>
          </Col>
          <Col span={3}>
            <div>過濾訂單時間</div>
          </Col>
          <Col span={21}>
            <DatePicker.RangePicker
              defaultValue={[
                moment(filters.start, 'YYYY-MM-DD'),
                moment(filters.end, 'YYYY-MM-DD'),
              ]}
              ranges={{
                本日: [moment(), moment()],
                本月: [moment().startOf('month'), moment().endOf('month')],
              }}
              onChange={(dates, dateStrings) => {
                filtersChange({
                  start: dateStrings[0],
                  end: dateStrings[1],
                });
              }}
              placeholder={['開始日期', '結束日期']}
            />
          </Col>
        </Row>
        <Row gutter={[16, 30]}>
          <Col span={6}>
            <Statistic title="總訂單數" value={statistic.order_count} />
          </Col>
          {/* <Col span={4}>
                          <Statistic title="本月成本" value={112893} />
                      </Col> */}
          <Col span={6}>
            <Statistic
              title={
                <Tooltip
                  title="已付款之所有訂單總額，包含一般、自定義、補收款、『點數訂單(訂單類型)』、月結訂單之訂單總額，
                但不包含『點數付款(付款方式)』方式、退款完成、已取消的訂單">
                  營收總額
                </Tooltip>
              }
              value={statistic.revenue == null ? 0 : statistic.revenue}
            />
          </Col>
          {/* <Col span={6}>
            <Statistic
              title="尚未出貨訂單"
              value={statistic.not_transit}
            />
          </Col> */}
          <Col span={12}>
            <Statistic
              title={
                <Tooltip title="包含從訂單成立開始(等待付款、為上傳稿件)至訂單完成前的訂單，不包含訂單完成、退款已完成、訂單已取消的訂單">
                  待處理訂單
                </Tooltip>
              }
              value={statistic.not_completed}
            />
          </Col>
          <Col span={5}>
            <Statistic
              title={<Tooltip title="僅計算等待審稿之訂單">審稿訂單</Tooltip>}
              value={statistic.review_count}
            />
          </Col>
          <Col span={5}>
            <Statistic
              title={
                <Tooltip title="已入庫之訂單，不包含在內">印製訂單</Tooltip>
              }
              value={statistic.prod_count}
            />
          </Col>
          <Col span={5}>
            <Statistic
              title={
                <Tooltip title="已送達/已自取之訂單，不包含在內">
                  出貨訂單
                </Tooltip>
              }
              value={statistic.log_count}
            />
          </Col>
          <Col span={5}>
            <Statistic
              title={
                <Tooltip title="完成售後之訂單，不包含在內">售後訂單</Tooltip>
              }
              value={statistic.ass_count}
            />
          </Col>
          <Col span={4}>
            <Statistic
              title={
                <Tooltip title="完成退款之訂單，不包含在內">退款訂單</Tooltip>
              }
              value={statistic.refund_count}
            />
          </Col>
        </Row>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 20px;
  padding: 25px;
  background-color: #ffffff;
`;

const Container = styled.div`
  flex: 1;

  & > .title {
    color: blue;
  }

  & > .data {
    color: red;
  }
`;

const Title = styled.div`
  color: #999;
  font-size: 20px;
  font-weight: 600;
`;
